import React, { Component } from "react";
import "./Collector.css";

export class EmailsCollector extends Component {
  constructor(props) {
    super(props);
    this.state = { reason: "collected", errors: "", email: "", show: "submit" };
    this.collectPhrase = "See new items before everyone else";
    this.successPhrase = "We will contact you...";
    this.api_localization =
      process.env.NODE_ENV === "development"
        ? "http://localhost:9000"
        : "https://journal-278208.ew.r.appspot.com";
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validateEmail() && this.sendToApi() && this.setState({ show: "success" });
  };

  handleChange = (event) => {
    this.setState({ email: event.target.value });
  };

  sendToApi = () => {
    fetch(this.api_localization + "/messages/new", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(this.state),
    })
      .then((result) => result.json())
      .then((info) => {
        console.log(info);
      });
      return true;
  };

  validateEmail = () => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
      this.setState({ errors: true });
      return false;
    }
    this.setState({ errors: false });
    return true;
  };

  render() {
    return (
      <div
        className="post_container"
        style={{ backgroundColor: "#181818", padding: "10px 0" }}
      >
        <h2
          style={{ display: this.state.show === "success" ? "block" : "none" }}
          className="email_collector_title"
        >
          {this.successPhrase}
        </h2>
        <form
          style={{ display: this.state.show === "submit" ? "block" : "none" }}
          onSubmit={this.handleSubmit}
          method="POST"
          className="email_form"
        >
          <h2 className="email_collector_title">{this.collectPhrase}</h2>
          <div className="center">
            <label>
              <input
                type="email"
                name="email"
                placeholder="email address"
                value={this.state.email}
                onChange={this.handleChange}
                className="email_input"
                autoComplete="on"
                style={{ borderColor: this.state.errors ? "red" : "black" }}
              />
            </label>
            <button type="submit" className="email_submit">
              submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default EmailsCollector;
