import React, { Component } from "react";
import "./Contact.css";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { reason: "question", email: "", message: "", status: "" };
    this.pageTitle = "Write us";
    this.pageMessage =
      "Do you have any questions? Suggestions? Do you want to know about advertising opportunities? Send us a note below ";
    this.api_localization =
      process.env.NODE_ENV === "development"
        ? "http://localhost:9000"
        : "https://journal-278208.ew.r.appspot.com";
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validateEmail() &&
      this.validateMessage() &&
      this.sendToApi() &&
      this.setState({ message: "", status: "message sent" });
  };

  sendToApi = () => {
    fetch(this.api_localization + "/messages/new", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(this.state),
    })
      .then((result) => result.json())
      .then((info) => {
        console.log(info);
      });
    return true;
  };
  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  validateMessage = () => {
    if (this.state.message) {
      return true;
    }
    this.setState({ status: "message is empty" });
  };

  validateEmail = () => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
      this.setState({
        status: "make sure to indicate a correct email address",
      });
      return false;
    }
    return true;
  };

  render() {
    return (
      <div className="article_container">
        <h1>{this.pageTitle}</h1>
        <p>{this.pageMessage}</p>
        <form onSubmit={this.handleSubmit}>
          <label>
            <select
              name="reason"
              value={this.state.reason}
              onChange={this.handleChange}
            >
              <option defaultValue value="question">
                ask a question
              </option>
              <option value="suggest">suggest a link</option>
              <option value="promote">promote a product</option>
            </select>
          </label>
          <label>
            <input
              name="email"
              placeholder="your email"
              value={this.state.email}
              onChange={this.handleChange}
            ></input>
          </label>
          <label>
            <textarea
              name="message"
              placeholder="your message"
              value={this.state.message}
              onChange={this.handleChange}
            ></textarea>
          </label>
          <div style={{ fontFamily: "monospace" }}>{this.state.status}</div>
          <input className="submit" type="submit" value="submit" />
        </form>
      </div>
    );
  }
}

export default Contact;
