import React, { Component } from "react";
import PostCover from "./PostCover";
import "./Post.css";

export class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props._id,
      image: this.props.image,
      link: this.props.link,
      title: this.props.title,
      source_tag: this.props.source_tag,
      hidden: this.props.hidden,
      seo_link: this.props.seo_link,
      content: this.props.content,
    };
    this.SHOW_TAG_IN_DEV = true;
    this.SHOW_DELETE_IN_DEV = false;
    this.api_localization =
      process.env.NODE_ENV === "development"
        ? "http://localhost:9000"
        : "https://journal-278208.ew.r.appspot.com";
    this.flag = this.props.flag;

    this.seo_link = this.props.match ? this.props.match.params.seo_link : null;
  }

  componentDidMount = () => {
    !this.props._id && this.seo_link && this.getPostContent(this.seo_link);
    this.props.type === "article" &&
      this.setState({
        link: "/article/" + this.state.seo_link,
      });
  };

  getPostContent = (seo_link) => {
    fetch(this.api_localization + "/posts/" + seo_link, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => this.setState(json));
  };

  dateFromObjectId = () => {
    let date = new Date(parseInt(this.state._id.substring(0, 8), 16) * 1000);
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    return <div className="date">{`${year}-${month}-${day}`}</div>;
  };

  deletePost = (e) => {
    e.preventDefault();
    fetch(this.api_localization + "/posts/" + this.state._id, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((json) => console.log(json));
    this.setState({ hidden: true });
  };

  deleteButton = () => {
    return process.env.NODE_ENV === "development" && this.SHOW_DELETE_IN_DEV ? (
      <div onClick={this.deletePost} className="post_button btn_top_right">
        delete
      </div>
    ) : null;
  };

  openLink = () => {
    window.open(this.state.link, "_blank");
  };

  flagTag = () => {
    return this.flag && <div className="post_flag">{this.flag}</div>;
  };
  sourceTag = () => {
    return process.env.NODE_ENV === "development" && this.SHOW_TAG_IN_DEV ? (
      <div style={{ color: "white", background: "black" }}>
        {this.state.source_tag}
      </div>
    ) : null;
  };
  render() {
    return this.state.image && !this.state.hidden ? (
      <div className="post_container" onClick={this.openLink}>
        <img
          className={"post_image"}
          src={this.state.image}
          alt={this.state.title}
        ></img>
        {this.flagTag()}
        <div className="post_overlay">
          {this.deleteButton()}
          {this.sourceTag()}
          <div>
            <PostCover
              _id={this.state._id}
              text={this.state.title}
              link={this.state.link}
              content={this.state.content}
            />
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default Post;
