import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import Posts from "./Posts";
import Post from "./Post";
import Articles from "./Articles";
import Article from "./Article";
import Dashboard from "./Dashboard";
import About from "./About";
import Contact from "./Contact";
import ProtectedRoute from "./ProtectedRoute";

import "./App.css";
import "./Shadows.css";
import "./Text_Content.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.name = "Top List Magazine";
    this.shortDescription = "magazine for men";
    this.development = process.env.NODE_ENV === "development";
  }

  componentDidMount() {
    document.title = `${this.name} - ${this.shortDescription}`;
  }

  magazineName = () => {};

  dashboardLink = () => {
    return this.development ? (
      <span className="menu_choice">
        <Link to="/dashboard" className="menu_choice">
          dashboard
        </Link>
      </span>
    ) : null;
  };

  render() {
    return (
      <Router>
        <div className="top_container">
          <div className="magazine_name">{this.name}</div>
          <div className="menu_container">
            <span>
              <Link to="/" className="menu_choice">
                home
              </Link>
            </span>
            <span className="menu_choice">
              <Link to="/about" className="menu_choice">
                about
              </Link>
            </span>
            <span className="menu_choice">
              <Link to="/all-articles" className="menu_choice">
                articles
              </Link>
            </span>
            <span className="menu_choice">
              <Link to="/contact" className="menu_choice">
                contact
              </Link>
            </span>
            {this.dashboardLink()}
          </div>
        </div>
        <div className="content_container">
          <Routes>
            <Route path="/about" element= { <About />} />
            <Route path="/all-articles" element = {<Articles />} />
            <Route path={`/article/:seo_link`} element={<Article/>} />
            <Route path={`/post/:seo_link`} element={<Post/>} />
            <Route path="/contact" element ={<Contact />} />
            <Route exact path={`/sources/:source_tag`} element={<Posts/>} />
            {/* <ProtectedRoute path="/dashboard" element={<Dashboard/>} /> */}
            <Route path="/" element = {<Posts />} />
            <Route path="*" element = {<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;
