import React, { Component } from "react";
import Post from "./Post";
import Article from "./Article";
import Spinner from "./utilities/Spinner";

class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = { articles: [] };
    this.source_tag = this.props.match
      ? this.props.match.params.source_tag
      : null;
    this.api_localization =
      process.env.NODE_ENV === "development"
        ? "http://localhost:9000"
        : "https://journal-278208.ew.r.appspot.com";
  }

  componentDidMount() {
    this.getLastArticleFromApi();
  }

  getLastArticleFromApi = () => {
    let articlePath = "/posts/all?type=article";
    fetch(this.api_localization + articlePath)
      .then((res) => res.json())
      .then((articles) => {
        this.setState({ articles: articles });
      });
  };

  render() {
    return this.state.articles.length ? (
      <div>
        {this.state.articles.map((article, i) => (
          !i ? (<Article
            key={article._id}
            api_localization={this.api_localization}
            {...article}
          />) : (<Post
            key={article._id}
            api_localization={this.api_localization}
            {...article}
          />)
        ))}
      </div>
    ) : (
      <Spinner/>
    );
  }
}

export default Articles;
