import React, { Component } from "react";
import "./PostCover.css";

class PostCover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beforeText: "",
      bigWord: "",
      afterText: "",
      textBeforeSpecial: "",
      special: "",
      textAfterSpecial: "",
    };
    this.shortlink =
      this.props.link &&
      this.props.link
        .replace("http://", "")
        .replace("https://", "")
        .split(/[/?#]/)[0];
  }

  componentDidMount() {
    let text = this.props.text.toUpperCase();
    text = text.trim();
    let textAfterBeginning = this.setTheBeginning(text);
    let endText = this.setTheBigWord(textAfterBeginning);
    this.setTheAfterText(endText.toUpperCase());
  }

  dateFromObjectId = () => {
    let date = new Date(parseInt(this.props._id.substring(0, 8), 16) * 1000);
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();
    return <div className="date">{`${year}-${month}-${day}`}</div>;
  };

  setTheBeginning = (text) => {
    let beforeStr = "";
    text = text.trim();
    text = text.toLowerCase();
    text.startsWith("the") && (beforeStr = "the");
    text.startsWith("this") && (beforeStr = "this");
    text.startsWith("a ") && (beforeStr = "a");
    this.setState({ beforeText: beforeStr });
    return text.substring(beforeStr.length);
  };

  setTheBigWord = (text) => {
    let bigWordString = text.trim().split(" ")[0];
    this.setState({ bigWord: bigWordString.toUpperCase() });
    let wordsAfterFirst = text.substring(bigWordString.length + 1);
    return wordsAfterFirst;
  };
  setTheAfterText = (text) => {
    let array = text.split(" ");
    let rand = Math.floor(Math.random() * (array.length - 1));
    let special = " " + array[rand] + " ";
    var before = array.slice(0, rand);
    var after = array.slice(rand + 1);
    var beforeStr = before.join(" ");
    var afterStr = after.join(" ");
    this.setState({
      textBeforeSpecial: beforeStr,
      special: special,
      textAfterSpecial: afterStr,
    });
  };

  showArticlePreview = () => {
    return this.props.content ? (
      <div className="article_preview">{this.props.content.substring(3, 150) + "..."}</div>
    ) : null;
  };

  seoLink = () => {
    return this.props.post_seo_link ? (
      <a href={"/post/" + this.props.post_seo_link}>SEO LINK</a>
    ) : null;
  };

  render() {
    return (
      <div>
        <div className="container">
          {/* {this.seoLink()} */}
          <div className="regular">{this.state.beforeText}</div>
          <div className="big">{this.state.bigWord}</div>
          <div className="regular">
            <span>
              {this.state.textBeforeSpecial}
              <span className="special">{this.state.special}</span>
              {this.state.textAfterSpecial}
            </span>
          </div>
        </div>
        <div>{this.dateFromObjectId()}</div>
        <div className="short_link">{this.showArticlePreview()}</div>
        <div className="short_link">{this.shortlink}</div>
        <a
          className="post_button btn_bottom_right"
          href={this.props.link}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div>more</div>
        </a>
      </div>
    );
  }
}

export default PostCover;
