import React, { Component } from "react";
import "./Post.css";

export class MinipostsWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      miniposts: this.props.miniposts,
    };
  }

  componentDidMount = () => {
    console.log(this.state.miniposts);
  };

  openLink = (link) => {
    window.open(link, "_blank");
    // console.log(link);
  };

  minipostSingle = (minipost) => {
    // return <div>{minipost.title}</div>;
    return (
      <th
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: "url(" + minipost.image + ")",
          cursor: "pointer",
        padding: "20px",
        fontFamily: "var(--postFont)",
        textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
        fontWeight: "normal",
        color:"white"
        }}
        className=""
        onClick={() => {
          this.openLink(minipost.link);
        }}
      >
        <span>{minipost.title}</span>
      </th>
    );
  };

  render() {
    return this.state.miniposts[0] ? (
      <table style={{ width: "100%" }}>
        <tbody>
          <tr style={{ height: "200px", width: "50%" }}>
            {this.minipostSingle(this.state.miniposts[0])}
            {this.minipostSingle(this.state.miniposts[1])}
          </tr>
          <tr style={{ height: "200px", width: "50%" }}>
            {this.minipostSingle(this.state.miniposts[2])}
            {this.minipostSingle(this.state.miniposts[3])}
          </tr>
        </tbody>
      </table>
    ) : null;
  }
}

export default MinipostsWidget;
