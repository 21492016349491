import React, { Component } from "react";

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      image: this.props.image,
      content: this.props.content,
      bottom_images: this.props.bottom_images || []
    };
    this.seo_link = this.props.match ? this.props.match.params.seo_link : null;
    this.api_localization =
      process.env.NODE_ENV === "development"
        ? "http://localhost:9000"
        : "https://journal-278208.ew.r.appspot.com";
  }

  componentDidMount = () => {
    console.log("props" + JSON.stringify(this.props));
    console.log(this.seo_link);
    !this.state.title &&
      this.seo_link &&
      this.getArticleFromApi(this.seo_link);
  };

  getArticleFromApi = (seo_link) => {
    console.log("getting artifcle");
    fetch(this.api_localization + "/posts/" + seo_link, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => this.setState(json));
  };

  render() {
    return (
      <>
        <div className="article_container">
          {this.state.image && (
            <img
              className="article_banner_image"
              src={this.state.image}
              alt={this.state.title}
            />
          )}
          <h1>{this.state.title}</h1>
          <div
            className="paragraph_container"
            dangerouslySetInnerHTML={{ __html: this.state.content }}
          ></div>
          {this.state.bottom_images.map((image, i) => {
            return (
              <img
                key={`image-${i}`}
                className="article_banner_image"
                src={image.link}
                alt={this.state.title}
              />
            );
          })}
        </div>
        <div className="article_link_container"></div>
        <div className="author_container"></div>
      </>
    );
  }
  
  // render() {
  //   return <h1>{this.state.title}</h1>
  // }
  
}
export default Article;
