import React, { Component } from "react";
import Article from "./Article";

class About extends Component {
  constructor(props) {
    super(props);
    this.title = "Our philosophy";
    this.image = "";
    this.content = ` <p> This magazine was created by people, who are interested in a particular type of stuff. 
    There is no precise way of discribing it, but the content you can find on this website perfectly reflects our interests. 
    We value quality, we value uniqueness, but most of all we want to be the first to get our hand on the best items.</p>
    <p>The content of this magazine is also a great source of inspiration. The miracles of modern technology 
    breeds fresh ideas as the talent of the engineers provides with energy to push beyond any imaginary self limitation. 
    We don't expect you to align with what we like, nor we expect you to understand. And to be honest, we don't really care. </p>
    `;
    this.bottomImage = "/about.jpg";

    this.state = {
      title: this.title,
      image: this.image,
      content: this.content,
      bottom_images: [{ link: this.bottomImage }],
    };
  }

  render() {
    return <Article {...this.state} />;
  }
}

export default About;
