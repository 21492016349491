import React, { Component } from "react";
import Post from "./Post";
import MinipostsWidget from "./MinipostsWidget";
// import Article from "./Article";
import EmailsCollector from "./Collector";
import Spinner from "./utilities/Spinner";

class Posts extends Component {
  constructor(props) {
    super(props);
    this.state = { posts: [], miniposts: [] };
    this.source_tag = this.props.match
      ? this.props.match.params.source_tag
      : null;
    this.api_localization =
      process.env.NODE_ENV === "development"
        ? "http://localhost:9000"
        : "https://journal-278208.ew.r.appspot.com";
  }

  componentDidMount() {
    console.log("component did mount : POSTS");
    let path = !this.source_tag
      ? "/posts/all"
      : "/posts/source/" + this.source_tag;
      console.log("Path = " + path);
    this.getPostsFromApi(path);
  }

  getPostsFromApi = (path) => {
    fetch(this.api_localization + path)
      .then((res) => res.json())
      .then((posts) => {
        console.log("posts are received from the api");
        this.setMinipostsCollection(posts);
        posts.splice(1, 0, { type: "mail_collector" });
        posts.splice(1, 0, { type: "miniposts_widget" });
        posts[0].flag = "hot!";
        this.setState({ posts: posts });
        this.getLastArticleFromApi();
      });
  };

  setMinipostsCollection = (posts) => {
    this.setState({ miniposts: [posts[3], posts[4], posts[5], posts[6]] });
    this.setState({ posts: posts.splice(3,4) });
    
  };

  getLastArticleFromApi = () => {
    let articlePath = "/posts/all?type=article";
    fetch(this.api_localization + articlePath)
      .then((res) => res.json())
      .then((articles) => {
        let posts = this.state.posts;
        // articles[0].flag = "article";
        posts.splice(1, 0, articles[0]);
        this.setState({ posts: posts });
      });
  };

  render() {
    return this.state.posts.length ? (
      <div>
        {this.state.posts.map((post, index) =>
          post.type === "mail_collector" ? (
            <EmailsCollector key={"collector"} />
          ) : !post.type ? (
            <Post
              key={post._id}
              api_localization={this.api_localization}
              {...post}
            />
          ) : post.type === "miniposts_widget" ? (
            <MinipostsWidget
              key="minipostsWidget"
              miniposts={this.state.miniposts}
            />
          ) : (
            <Post
              key={post._id}
              api_localization={this.api_localization}
              {...post}
            />
          )
        )}
      </div>
    ) : (
      <Spinner />
    );
  }
}

export default Posts;
