import React, { Component } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      title: "",
      image: "",
      content: "",
      type:"article"
    };
    this.api_localization =
    process.env.NODE_ENV === "development"
      ? "http://localhost:9000"
      : "https://journal-278208.ew.r.appspot.com";

  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState: editorState,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  sendToApi = () => {
    fetch(this.api_localization +"/posts/newpost", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({title: this.state.title, image: this.state.image, content: this.state.content, type: this.state.type}),
    })
      .then((result) => result.json())
      .then((info) => {
        console.log(info);
      });
    return true;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.sendToApi() && this.setState({ title: "", image: "", content: "" });
  };

  render() {
    const editorState = this.state.editorState;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <input
            name="title"
            placeholder="Title"
            style={{ width: "280px" }}
            onChange={this.handleChange}
            value={this.state.title}
          ></input>
          <input
            name="image"
            placeholder="Image link"
            style={{ width: "280px", margin: "0 10px" }}
            onChange={this.handleChange}
            value={this.state.image}
          ></input>
          <input className="submit" type="submit" value="Submit" />
        </form>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          editorStyle={{ background: "#fefefe", padding: "0 20px" }}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "list",
              "textAlign",
              "colorPicker",
              "link",
              "emoji",
              "image",
              "remove",
              "history",
            ],
          }}
        />
        <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        />
      </div>
    );
  }
}

export default Dashboard;
