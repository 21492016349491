import React from "react";
import { Navigate } from "react-router-dom";

class ProtectedRoute extends React.Component {
  render() {
    const Component = this.props.component;
    const isAuthenticated = process.env.NODE_ENV === "development";

    return isAuthenticated ? (
      <Component />
    ) : (
      <Navigate to={{ pathname: "/" }} />
    );
  }
}

export default ProtectedRoute;
